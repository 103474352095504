import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/**
 * 获取维修部门
 * @param params
 */
export function getImpDept(params) {
    return request({
        url: envInfo.bgApp.orderPath + '/tenant/orderConfig/getImpDept',
        method: 'get',
        params
    })
}

/**
 *  提交工单
 * @param params
 */
export function deviceEnterOrder(params) {
    return request({
        url: envInfo.bgApp.orderPath + '/tenant/orderFill/enterOrder',
        method: 'post',
        data: params
    })
}
