var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          staticClass: "order-drawer",
          staticStyle: { "line-height": "16px" },
          attrs: {
            size: 638,
            visible: _vm.drawer,
            direction: _vm.direction,
            "before-close": _vm.handleCloseDrawer,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c(
              "span",
              [_c("title-icon"), _vm._v(_vm._s(_vm.$t("order.fill")))],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "data-list" },
            [
              _c(
                "el-row",
                { staticClass: "data-row" },
                [
                  _c("el-col", { attrs: { span: 4, offset: 1 } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("base.project.name")) +
                        "\n        "
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 18 } }, [
                    _c(
                      "span",
                      { staticClass: "notify-sender" },
                      [
                        _c(
                          "el-select",
                          {
                            style: { width: "100%" },
                            attrs: { clearable: "" },
                            on: { change: _vm.changeFormProject },
                            model: {
                              value: _vm.form.projectId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "projectId", $$v)
                              },
                              expression: "form.projectId",
                            },
                          },
                          _vm._l(_vm.projectList, function (item) {
                            return _c("el-option", {
                              key: item.projectId,
                              attrs: {
                                label: item.projectName,
                                value: item.projectId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "data-row" },
                [
                  _c("el-col", { attrs: { span: 4, offset: 1 } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("base.position.name")) +
                        "\n        "
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 18 } }, [
                    _c(
                      "span",
                      { staticClass: "notify-sender" },
                      [
                        _c("tree-select", {
                          attrs: {
                            "disable-branch-nodes": true,
                            multiple: false,
                            "show-count": true,
                            placeholder: _vm.$t("commons.selectPlease"),
                            options: _vm.formPositionList,
                          },
                          model: {
                            value: _vm.form.positionId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "positionId", $$v)
                            },
                            expression: "form.positionId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "data-row" },
                [
                  _c("el-col", { attrs: { span: 4, offset: 1 } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("base.device.select")) +
                        "\n        "
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 18 } }, [
                    _c(
                      "span",
                      { staticClass: "notify-sender" },
                      [
                        _c("el-autocomplete", {
                          attrs: {
                            "fetch-suggestions": _vm.querySearch,
                            clearable: "",
                            placeholder: _vm.$t("commons.pleaseInput"),
                          },
                          on: {
                            clear: _vm.clearDevice,
                            select: _vm.handleSelect,
                            input: _vm.initDeviceList,
                          },
                          model: {
                            value: _vm.inputValue,
                            callback: function ($$v) {
                              _vm.inputValue = $$v
                            },
                            expression: "inputValue",
                          },
                        }),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "6px" },
                            attrs: { type: "primary", icon: "el-icon-search" },
                            on: { click: _vm.openDeviceSearch },
                          },
                          [_vm._v(_vm._s(_vm.$t("base.device.advanced")))]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "data-row" },
                [
                  _c("el-col", { attrs: { span: 4, offset: 1 } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("order.faultDesc")) +
                        "\n        "
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 18 } }, [
                    _c(
                      "span",
                      { staticClass: "notify-sender" },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            maxlength: "80",
                            "show-word-limit": true,
                            rows: 2,
                            placeholder: _vm.$t("commons.pleaseInput"),
                          },
                          model: {
                            value: _vm.form.problemDesc,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "problemDesc", $$v)
                            },
                            expression: "form.problemDesc",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "data-row" },
                [
                  _c("el-col", { attrs: { span: 4, offset: 1 } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("order.orderType")) +
                        "\n        "
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 18 } }, [
                    _c(
                      "span",
                      { staticClass: "notify-sender" },
                      [
                        _c(
                          "el-select",
                          {
                            style: { width: "100%" },
                            model: {
                              value: _vm.form.orderType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "orderType", $$v)
                              },
                              expression: "form.orderType",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: {
                                value: "1",
                                label: _vm.$t("order.device"),
                              },
                            }),
                            _c("el-option", {
                              attrs: {
                                value: "2",
                                label: _vm.$t("order.fireSafety"),
                              },
                            }),
                            _c("el-option", {
                              attrs: {
                                value: "3",
                                label: _vm.$t("order.quality"),
                              },
                            }),
                            _c("el-option", {
                              attrs: {
                                value: "4",
                                label: _vm.$t("order.sporadicMaintenance"),
                              },
                            }),
                            _c("el-option", {
                              attrs: {
                                value: "5",
                                label: _vm.$t("order.cleaningHygiene"),
                              },
                            }),
                            _c("el-option", {
                              attrs: {
                                value: "6",
                                label: _vm.$t("order.greenConservation"),
                              },
                            }),
                            _c("el-option", {
                              attrs: {
                                value: "7",
                                label: _vm.$t("order.vulnerabilityInformation"),
                              },
                            }),
                            _c("el-option", {
                              attrs: {
                                value: "8",
                                label: _vm.$t("order.customerDeclaration"),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "data-row" },
                [
                  _c("el-col", { attrs: { span: 4, offset: 1 } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("order.level")) +
                        "\n        "
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 18 } }, [
                    _c(
                      "span",
                      { staticClass: "notify-sender" },
                      [
                        _c(
                          "el-select",
                          {
                            style: { width: "100%" },
                            model: {
                              value: _vm.form.orderLevel,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "orderLevel", $$v)
                              },
                              expression: "form.orderLevel",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: {
                                value: "0",
                                label: _vm.$t("order.config.ordinary"),
                              },
                            }),
                            _c("el-option", {
                              attrs: {
                                value: "1",
                                label: _vm.$t("order.config.urgent"),
                              },
                            }),
                            _c("el-option", {
                              attrs: {
                                value: "2",
                                label: _vm.$t("order.config.alarm"),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              !(_vm.form.bySelfChecked || _vm.form.repairPerson != null)
                ? [
                    _c(
                      "el-row",
                      { staticClass: "data-row" },
                      [
                        _c("el-col", { attrs: { span: 4, offset: 1 } }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("order.serviceDept")) +
                              "\n          "
                          ),
                        ]),
                        _c("el-col", { attrs: { span: 18 } }, [
                          _c(
                            "span",
                            { staticClass: "notify-sender" },
                            [
                              _c(
                                "el-select",
                                {
                                  style: { width: "100%" },
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.form.resDeptId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "resDeptId", $$v)
                                    },
                                    expression: "form.resDeptId",
                                  },
                                },
                                _vm._l(_vm.departmentList, function (item) {
                                  return _c("el-option", {
                                    key: item.deptId,
                                    attrs: {
                                      label: item.deptName,
                                      value: item.deptId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c(
                "el-row",
                { staticClass: "data-row" },
                [
                  _c("el-col", { attrs: { span: 4, offset: 1 } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("order.livePhoto")) +
                        "\n        "
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 18 } }, [
                    _c(
                      "span",
                      { staticClass: "notify-sender" },
                      [
                        _c(
                          "div",
                          { staticClass: "image_container" },
                          _vm._l(_vm.imageList, function (image, index) {
                            return _c(
                              "span",
                              { key: index, staticClass: "image_single" },
                              [
                                _c("img", { attrs: { src: image.url } }),
                                _c("i", {
                                  staticClass:
                                    "el-icon-circle-plus-outline img_oper_icon",
                                  on: {
                                    click: function ($event) {
                                      return _vm.singleImagePreview(image)
                                    },
                                  },
                                }),
                                _c("i", {
                                  staticClass: "el-icon-delete img_oper_icon",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.singleImageDelete(image)
                                    },
                                  },
                                }),
                              ]
                            )
                          }),
                          0
                        ),
                        _c(
                          "el-upload",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.isUploading,
                                expression: "isUploading",
                              },
                            ],
                            ref: "uploadFaultImage",
                            attrs: {
                              "list-type": "picture-card",
                              "show-file-list": false,
                              "on-success": _vm.uploadSuccess,
                              "on-error": _vm.uploadFail,
                              action: "#",
                              "with-credentials": true,
                              "on-preview": _vm.handlePictureCardPreview,
                              "on-progress": _vm.handleUploading,
                              "on-remove": _vm.handleRemove,
                              "on-exceed": _vm.handleExceed,
                              "before-upload": _vm.beforeAvatarUpload,
                              "http-request": _vm.httpRequest,
                              accept: ".jpg,.png",
                              drag: "",
                              multiple: "",
                              limit: 9,
                            },
                          },
                          [_c("i", { staticClass: "el-icon-plus" })]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "data-row" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4, offset: 1 } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.form.bySelfChecked,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "bySelfChecked", $$v)
                            },
                            expression: "form.bySelfChecked",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("order.makeMe")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "data-row" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "90%", "margin-left": "5%" },
                      attrs: { type: "primary", disabled: !_vm.canSubmit() },
                      on: { click: _vm.submitOrder },
                    },
                    [_vm._v(_vm._s(_vm.$t("commons.submit")))]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { height: "96vh" },
          attrs: { visible: _vm.imageDialogVisible, width: "50%", top: "6vh" },
          on: {
            "update:visible": function ($event) {
              _vm.imageDialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { height: "80vh", width: "100%" },
            attrs: { src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { "line-height": "normal" },
          attrs: {
            visible: _vm.dialogVisible,
            width: "80%",
            top: "6vh",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _c("title-icon"),
              _vm._v(_vm._s(_vm.$t("base.device.select")) + "\n    "),
            ],
            1
          ),
          _c(
            "span",
            [
              _c(
                "el-form",
                { attrs: { role: "form", "label-width": "100px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("base.project.name") } },
                            [
                              _c(
                                "el-select",
                                {
                                  ref: "project",
                                  style: { width: "100%" },
                                  attrs: { clearable: "" },
                                  on: { change: _vm.changeProject },
                                  model: {
                                    value: _vm.device.listQuery.projectId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.device.listQuery,
                                        "projectId",
                                        $$v
                                      )
                                    },
                                    expression: "device.listQuery.projectId",
                                  },
                                },
                                _vm._l(_vm.projectList, function (item) {
                                  return _c("el-option", {
                                    key: item.projectId,
                                    attrs: {
                                      label: item.projectName,
                                      value: item.projectId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: _vm.$t("base.device.position") },
                            },
                            [
                              _c("tree-select", {
                                attrs: {
                                  "disable-branch-nodes": true,
                                  multiple: false,
                                  "show-count": true,
                                  placeholder: _vm.$t("commons.selectPlease"),
                                  options: _vm.positionList,
                                },
                                on: { select: _vm.searchCondition },
                                model: {
                                  value: _vm.device.listQuery.positionId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.device.listQuery,
                                      "positionId",
                                      $$v
                                    )
                                  },
                                  expression: "device.listQuery.positionId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: _vm.$t("base.category.system") },
                            },
                            [
                              _c("tree-select", {
                                attrs: {
                                  multiple: false,
                                  "show-count": true,
                                  placeholder: _vm.$t("commons.selectPlease"),
                                  options: _vm.categoryList,
                                },
                                on: { select: _vm.searchCondition },
                                model: {
                                  value: _vm.device.listQuery.categoryId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.device.listQuery,
                                      "categoryId",
                                      $$v
                                    )
                                  },
                                  expression: "device.listQuery.categoryId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("base.device.name") } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "25",
                                  "show-word-limit": true,
                                  placeholder: _vm.$t("commons.pleaseInput"),
                                  clearable: "",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.searchCondition.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.device.listQuery.deviceName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.device.listQuery,
                                      "deviceName",
                                      $$v
                                    )
                                  },
                                  expression: "device.listQuery.deviceName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("base.device.factoryCode"),
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "50",
                                  "show-word-limit": true,
                                  placeholder: _vm.$t("commons.pleaseInput"),
                                  clearable: "",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.searchCondition.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.device.listQuery.factoryCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.device.listQuery,
                                      "factoryCode",
                                      $$v
                                    )
                                  },
                                  expression: "device.listQuery.factoryCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c(
                            "el-button",
                            {
                              style: { marginLeft: "30px" },
                              attrs: {
                                type: "primary",
                                icon: "el-icon-search",
                                loading: _vm.device.listLoading,
                              },
                              on: { click: _vm.searchCondition },
                            },
                            [_vm._v(_vm._s(_vm.$t("commons.search")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.device.listLoading,
                      expression: "device.listLoading",
                    },
                  ],
                  ref: "singleTable",
                  attrs: {
                    data: _vm.device.list,
                    "highlight-current-row": "",
                    "element-loading-text": "loading",
                    border: "",
                    fit: "",
                    stripe: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "projectName",
                      label: _vm.$t("base.project.name"),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.projectName))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "deviceName",
                      label: _vm.$t("base.device.name"),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.deviceName))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("base.device.position") },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: scope.row.descName,
                                  placement: "top",
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.positionName)),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("base.category.system") },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.categoryName)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("commons.actions"),
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectDevice(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("commons.select")))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.device.total > 0,
                    expression: "device.total > 0",
                  },
                ],
                attrs: {
                  total: _vm.device.total,
                  page: _vm.device.listQuery.current,
                  limit: _vm.device.listQuery.rowCount,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.device.listQuery, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.device.listQuery, "rowCount", $event)
                  },
                  pagination: _vm.getDevicePage,
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }