var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-remove": _vm.removeTab },
          model: {
            value: _vm.activeTabName,
            callback: function ($$v) {
              _vm.activeTabName = $$v
            },
            expression: "activeTabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              key: _vm.listTab.tabName,
              attrs: { label: _vm.$t("order.list"), name: _vm.listTab.tabName },
            },
            [
              _c(
                "el-form",
                { attrs: { role: "form", "label-width": "100px" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("base.company.name") } },
                            [
                              _c(
                                "el-select",
                                {
                                  style: { width: "100%" },
                                  attrs: { clearable: "" },
                                  on: {
                                    change: _vm.changeCompany,
                                    clear: function ($event) {
                                      return _vm.clearEmpty("companyId")
                                    },
                                  },
                                  model: {
                                    value: _vm.listQuery.companyId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "companyId", $$v)
                                    },
                                    expression: "listQuery.companyId",
                                  },
                                },
                                _vm._l(_vm.companyList, function (item) {
                                  return _c("el-option", {
                                    key: item.companyId,
                                    attrs: {
                                      value: item.companyId,
                                      label: item.companyName,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("base.project.name") } },
                            [
                              _c(
                                "el-select",
                                {
                                  style: { width: "100%" },
                                  attrs: {
                                    disabled: _vm.ableProject,
                                    clearable: "",
                                  },
                                  on: { change: _vm.projectChange },
                                  model: {
                                    value: _vm.listQuery.projectId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "projectId", $$v)
                                    },
                                    expression: "listQuery.projectId",
                                  },
                                },
                                _vm._l(_vm.projectList, function (item) {
                                  return _c("el-option", {
                                    key: item.projectId,
                                    attrs: {
                                      value: item.projectId,
                                      label: item.projectName,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("order.workOrderName") } },
                            [
                              _c(
                                "el-select",
                                {
                                  style: { width: "100%" },
                                  attrs: {
                                    disabled: !_vm.listQuery.projectId,
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.listQuery.orderConfigId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.listQuery,
                                        "orderConfigId",
                                        $$v
                                      )
                                    },
                                    expression: "listQuery.orderConfigId",
                                  },
                                },
                                _vm._l(_vm.tempArrs, function (it, dex) {
                                  return _c("el-option", {
                                    key: dex,
                                    attrs: {
                                      value: it.orderConfigId,
                                      label: it.configName,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("order.serveTypeStr") } },
                            [
                              _c(
                                "el-select",
                                {
                                  style: { width: "100%" },
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.listQuery.serviceType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.listQuery,
                                        "serviceType",
                                        $$v
                                      )
                                    },
                                    expression: "listQuery.serviceType",
                                  },
                                },
                                _vm._l(_vm.servTypeArrs, function (it, dex) {
                                  return _c("el-option", {
                                    key: dex,
                                    attrs: {
                                      value: it.itemCode,
                                      label: it.itemName,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("order.declareDept") } },
                            [
                              _c(
                                "el-select",
                                {
                                  style: { width: "100%" },
                                  attrs: {
                                    disabled: _vm.ableDepartment,
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.listQuery.reportDeptId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.listQuery,
                                        "reportDeptId",
                                        $$v
                                      )
                                    },
                                    expression: "listQuery.reportDeptId",
                                  },
                                },
                                _vm._l(_vm.departmentList, function (item) {
                                  return _c("el-option", {
                                    key: item.deptId,
                                    attrs: {
                                      value: item.deptId,
                                      label: item.deptName,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("base.dept.responsibleDept"),
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  style: { width: "100%" },
                                  attrs: {
                                    disabled: _vm.ableDepartment,
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.listQuery.resDeptId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "resDeptId", $$v)
                                    },
                                    expression: "listQuery.resDeptId",
                                  },
                                },
                                _vm._l(_vm.departmentList, function (item) {
                                  return _c("el-option", {
                                    key: item.deptId,
                                    attrs: {
                                      value: item.deptId,
                                      label: item.deptName,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("order.declarePeople") } },
                            [
                              _c(
                                "el-select",
                                {
                                  style: { width: "100%" },
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.listQuery.reportUserId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.listQuery,
                                        "reportUserId",
                                        $$v
                                      )
                                    },
                                    expression: "listQuery.reportUserId",
                                  },
                                },
                                _vm._l(_vm.employeeList, function (item) {
                                  return _c("el-option", {
                                    key: item.userId,
                                    attrs: {
                                      value: item.userId,
                                      label: item.userName,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("base.dept.responsiblePerson"),
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  style: { width: "100%" },
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.listQuery.handlePerson,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.listQuery,
                                        "handlePerson",
                                        $$v
                                      )
                                    },
                                    expression: "listQuery.handlePerson",
                                  },
                                },
                                _vm._l(_vm.employeeList, function (item) {
                                  return _c("el-option", {
                                    key: item.userId,
                                    attrs: {
                                      value: item.userId,
                                      label: item.userName,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: _vm.$t("base.device.position") },
                            },
                            [
                              _c("tree-select", {
                                attrs: {
                                  miltiple: false,
                                  "show-count": true,
                                  placeholder: _vm.$t("commons.selectPlease"),
                                  options: _vm.editPositionList,
                                },
                                model: {
                                  value: _vm.listQuery.positionId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "positionId", $$v)
                                  },
                                  expression: "listQuery.positionId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("base.device.name") } },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "25", clearable: "" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.getOrderPage.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.listQuery.deviceName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "deviceName", $$v)
                                  },
                                  expression: "listQuery.deviceName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("commons.startTime") } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  placeholder: _vm.$t("commons.startTime"),
                                },
                                on: { change: _vm.pickerStart },
                                model: {
                                  value: _vm.listQuery.reportStartDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.listQuery,
                                      "reportStartDate",
                                      $$v
                                    )
                                  },
                                  expression: "listQuery.reportStartDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("commons.endTime") } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  placeholder: _vm.$t("commons.endTime"),
                                },
                                on: { change: _vm.pickerEnd },
                                model: {
                                  value: _vm.listQuery.reportEndDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.listQuery,
                                      "reportEndDate",
                                      $$v
                                    )
                                  },
                                  expression: "listQuery.reportEndDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("order.orderCode") } },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "80", clearable: "" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.getOrderPage.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.listQuery.orderCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "orderCode", $$v)
                                  },
                                  expression: "listQuery.orderCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("order.level") } },
                            [
                              _c(
                                "el-select",
                                {
                                  style: { width: "100%" },
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.listQuery.orderLevel,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "orderLevel", $$v)
                                    },
                                    expression: "listQuery.orderLevel",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      value: "0",
                                      label: _vm.$t("order.config.ordinary"),
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      value: "1",
                                      label: _vm.$t("order.config.urgent"),
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      value: "2",
                                      label: _vm.$t("order.config.alarm"),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("order.status") } },
                            [
                              _c(
                                "el-select",
                                {
                                  style: { width: "100%" },
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.listQuery.orderStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.listQuery,
                                        "orderStatus",
                                        $$v
                                      )
                                    },
                                    expression: "listQuery.orderStatus",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      value: "6",
                                      label: _vm.$t("order.applyReject"),
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      value: "1",
                                      label: _vm.$t("order.waitReceive"),
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      value: "2",
                                      label: _vm.$t("order.waitExecute"),
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      value: "3",
                                      label: _vm.$t("order.executing"),
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      value: "4",
                                      label: _vm.$t("order.waitAcceptance"),
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      value: "5",
                                      label: _vm.$t("order.completed"),
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      value: "7",
                                      label: _vm.$t("order.acceptanceReject"),
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      value: "9",
                                      label: _vm.$t("order.alreadyCloseOrder"),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-row"),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "filter-container" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        loading: _vm.loading,
                      },
                      on: { click: _vm.searchWithCon },
                    },
                    [_vm._v(_vm._s(_vm.$t("commons.search")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-brush",
                        plain: "",
                      },
                      on: { click: _vm.clearForm },
                    },
                    [_vm._v(_vm._s(_vm.$t("commons.reset")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-download",
                        plain: "",
                      },
                      on: { click: _vm.exportOrder },
                    },
                    [_vm._v(_vm._s(_vm.$t("commons.export")))]
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    "element-loading-text": "Loading",
                    data: _vm.list,
                    border: "",
                    stripe: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("base.project.name"),
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (s) {
                          return [
                            _c("span", [_vm._v(_vm._s(s.row.projectName))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("base.device.position"),
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (s) {
                          return [
                            _c("span", [_vm._v(_vm._s(s.row.positionName))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("base.device.name"),
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (s) {
                          return [
                            _c("span", [_vm._v(_vm._s(s.row.deviceName))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("order.orderCode"),
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (s) {
                          return [_c("span", [_vm._v(_vm._s(s.row.orderCode))])]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("order.faultDesc"),
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (s) {
                          return [
                            !s.row.alarmVo
                              ? _c("span", [_vm._v(_vm._s(s.row.problemDesc))])
                              : _c("span", [_vm._v(_vm._s(s.row.alarmDesc))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("order.orderConfigName"),
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (s) {
                          return [
                            _c("span", [_vm._v(_vm._s(s.row.configName))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("order.orderType"),
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (s) {
                          return [
                            _c("span", [_vm._v(_vm._s(s.row.orderTypeStr))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("order.serveTypeStr"),
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (s) {
                          return [
                            _c("span", [_vm._v(_vm._s(s.row.serviceType))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("order.serviceDept"),
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (s) {
                          return [
                            _c("span", [_vm._v(_vm._s(s.row.resDeptName))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("order.servicePeople"),
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (s) {
                          return [
                            s.row.orderStatus == 1
                              ? [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#ffc107" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("order.waitReceive"))
                                      ),
                                    ]
                                  ),
                                ]
                              : s.row.orderStatus == 6
                              ? [
                                  s.row.isSelf == "Y"
                                    ? _c("span", [
                                        _vm._v(_vm._s(s.row.submitUserName)),
                                      ])
                                    : _c("span", [_vm._v("/")]),
                                ]
                              : [
                                  _c("span", [
                                    _vm._v(_vm._s(s.row.handlePersonName)),
                                  ]),
                                ],
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("order.signTime"),
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (s) {
                          return [
                            s.row.orderSign
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        s.row.orderSign.creationDate,
                                        "YYYY-MM-DD HH:mm:ss"
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("order.declareDept"),
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (s) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(s.row.submitUserDeptName)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("order.declarePeople"),
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (s) {
                          return [
                            _c("span", [_vm._v(_vm._s(s.row.submitUserName))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("order.status"),
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (s) {
                          return [
                            s.row.orderStatus == 1
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.$t("order.waitReceive"))),
                                ])
                              : s.row.orderStatus == 2
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.$t("order.waitExecute"))),
                                ])
                              : s.row.orderStatus == 3
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.$t("order.executing"))),
                                ])
                              : s.row.orderStatus == 4
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("order.waitAcceptance"))
                                  ),
                                ])
                              : s.row.orderStatus == 5
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.$t("order.completed"))),
                                ])
                              : s.row.orderStatus == 6
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.$t("order.applyReject"))),
                                ])
                              : s.row.orderStatus == 7
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("order.acceptanceReject"))
                                  ),
                                ])
                              : s.row.orderStatus == 8
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.$t("order.alreadyRevoke"))),
                                ])
                              : s.row.orderStatus == 9
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("order.alreadyCloseOrder"))
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("order.declareTime"), width: "160" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (s) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    s.row.creationDate,
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("order.completeDetail"),
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (s) {
                          return [
                            s.row.orderStatus == 9
                              ? _c("span", [_vm._v(_vm._s(s.row.closeReason))])
                              : _c("span", [
                                  s.row.orderStatus !== 7
                                    ? _c("span", [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(s.row.completeSituation)
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("commons.details"),
                      align: "center",
                      width: "80",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (s) {
                          return [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: _vm.$t("commons.details"),
                                  placeman: "bottom",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    circle: "",
                                    type: "success",
                                    icon: "el-icon-tickets",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showOrderDetail(s.row)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.current,
                  limit: _vm.listQuery.rowCount,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "rowCount", $event)
                  },
                  pagination: _vm.getOrderPage,
                },
              }),
            ],
            1
          ),
          _vm.detailTab
            ? _c(
                "el-tab-pane",
                {
                  key: _vm.detailTab.tabName,
                  attrs: {
                    label: _vm.$t("order.details"),
                    name: _vm.detailTab.tabName,
                    closable: "",
                  },
                },
                [
                  _c("order-detail", {
                    key: _vm.order.orderId,
                    attrs: { orderRow: _vm.order },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }