<template>
	<div class="app-container">
		<el-tabs v-model="activeTabName" type="card" @tab-remove="removeTab">
			<el-tab-pane :label="$t('order.list')" :name="listTab.tabName" :key="listTab.tabName">
				<el-form role="form" label-width="100px">
					<el-row>
						<el-col :span="6">
							<!--公司-->
							<el-form-item :label="$t('base.company.name')">
								<el-select @change="changeCompany" clearable v-model="listQuery.companyId" @clear="clearEmpty('companyId')" :style="{ width: '100%' }">
									<el-option v-for="item in companyList" :value="item.companyId" :key="item.companyId" :label="item.companyName">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<!--项目名称-->
							<el-form-item :label="$t('base.project.name')">
								<el-select :disabled="ableProject" clearable v-model="listQuery.projectId" :style="{ width: '100%' }" @change='projectChange'>
									<el-option v-for="item in projectList" :value="item.projectId" :key="item.projectId" :label="item.projectName">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						
						
						<el-col :span="6">
							<!--模板名称-->
							<el-form-item :label="$t('order.workOrderName')">
								<el-select v-model="listQuery.orderConfigId"  :disabled="!listQuery.projectId" clearable :style="{ width: '100%' }">
									<el-option v-for='(it,dex) in tempArrs' :key='dex' :value="it.orderConfigId" :label="it.configName"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<!--服务类型-->
							<el-form-item :label="$t('order.serveTypeStr')">
								<el-select v-model="listQuery.serviceType" clearable :style="{ width: '100%' }">
									<el-option v-for='(it,dex) in servTypeArrs' :key='dex' :value="it.itemCode" :label="it.itemName"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<!--报单部门-->
							<el-form-item :label="$t('order.declareDept')">
								<el-select :disabled="ableDepartment" clearable v-model="listQuery.reportDeptId" :style="{ width: '100%' }">
									<el-option v-for="item in departmentList" :value="item.deptId" :key="item.deptId" :label="item.deptName">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<!--责任部门-->
							<el-form-item :label="$t('base.dept.responsibleDept')">
								<el-select :disabled="ableDepartment" clearable v-model="listQuery.resDeptId" :style="{ width: '100%' }">
									<el-option v-for="item in departmentList" :value="item.deptId" :key="item.deptId" :label="item.deptName">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<!--报单人员-->
							<el-form-item :label="$t('order.declarePeople')">
								<el-select v-model="listQuery.reportUserId" clearable :style="{ width: '100%' }">
									<el-option v-for="item in employeeList" :value="item.userId" :key="item.userId" :label="item.userName">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<!--责任人员-->
							<el-form-item :label="$t('base.dept.responsiblePerson')">
								<el-select v-model="listQuery.handlePerson" clearable :style="{ width: '100%' }">
									<el-option v-for="item in employeeList" :value="item.userId" :key="item.userId" :label="item.userName">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="6">
							<!--设备位置-->
							<el-form-item :label="$t('base.device.position')">
								<tree-select v-model="listQuery.positionId" :miltiple="false" :show-count="true" :placeholder="$t('commons.selectPlease')" :options="editPositionList" />
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<!--设备名称-->
							<el-form-item :label="$t('base.device.name')">
								<el-input v-model="listQuery.deviceName" maxlength="25" clearable @keyup.enter.native="getOrderPage"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<!--开始时间-->
							<el-form-item :label="$t('commons.startTime')">
								<el-date-picker @change="pickerStart" v-model="listQuery.reportStartDate" type="date" style="width: 100%" format="yyyy-MM-dd" :placeholder="$t('commons.startTime')">
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<!--结束时间-->
							<el-form-item :label="$t('commons.endTime')">
								<el-date-picker @change="pickerEnd" v-model="listQuery.reportEndDate" type="date" style="width: 100%" format="yyyy-MM-dd" :placeholder="$t('commons.endTime')">
								</el-date-picker>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="6">
							<!--工单编码-->
							<el-form-item :label="$t('order.orderCode')">
								<el-input v-model="listQuery.orderCode" maxlength="80" clearable @keyup.enter.native="getOrderPage"></el-input>
							</el-form-item>
						</el-col>
						
						<el-col :span="6">
							<!--工单级别-->
							<el-form-item :label="$t('order.level')">
								<el-select v-model="listQuery.orderLevel" clearable :style="{ width: '100%' }">
									<el-option value="0" :label="$t('order.config.ordinary')"></el-option>
									<el-option value="1" :label="$t('order.config.urgent')"></el-option>
									<el-option value="2" :label="$t('order.config.alarm')"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<!--工单状态-->
							<el-form-item :label="$t('order.status')">
								<el-select v-model="listQuery.orderStatus" clearable :style="{ width: '100%' }">
									<el-option value="6" :label="$t('order.applyReject')"></el-option>
									<el-option value="1" :label="$t('order.waitReceive')"></el-option>
									<el-option value="2" :label="$t('order.waitExecute')"></el-option>
									<el-option value="3" :label="$t('order.executing')"></el-option>
									<el-option value="4" :label="$t('order.waitAcceptance')"></el-option>
									<el-option value="5" :label="$t('order.completed')"></el-option>
									<el-option value="7" :label="$t('order.acceptanceReject')"></el-option>
									<!--<el-option value="8" :label="$t('order.alreadyRevoke')"></el-option>-->
									<el-option value="9" :label="$t('order.alreadyCloseOrder')"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					
					<el-row>
						<!--工单类型-->
						<!--<el-col :span="6">
							<el-form-item :label="$t('order.orderType')">
								<el-select v-model="listQuery.orderType" clearable :style="{ width: '100%' }">
									<el-option value="1" :label="$t('order.device')"></el-option>
									<el-option value="2" :label="$t('order.fireSafety')"></el-option>
									<el-option value="3" :label="$t('order.quality')"></el-option>
									<el-option value="4" :label="$t('order.sporadicMaintenance')"></el-option>
									<el-option value="5" :label="$t('order.cleaningHygiene')"></el-option>
									<el-option value="6" :label="$t('order.greenConservation')"></el-option>
									<el-option value="7" :label="$t('order.vulnerabilityInformation')"></el-option>
									<el-option value="8" :label="$t('order.customerDeclaration')"></el-option>
								</el-select>
							</el-form-item>
						</el-col>-->
						
						
						
						
					</el-row>
				</el-form>
				<div class="filter-container">
					<el-button type="primary" icon="el-icon-search" :loading="loading" @click="searchWithCon">{{ $t("commons.search") }}</el-button>
					<el-button type="primary" icon="el-icon-brush" plain @click="clearForm">{{ $t("commons.reset") }}</el-button>
					<el-button type="primary" icon="el-icon-download" plain @click="exportOrder">{{ $t("commons.export") }}</el-button>
					<!--<el-button type="primary" icon="el-icon-plus" @click="drawerClick" plain>{{ $t("order.place") }}</el-button>-->
				</div>

				<el-table v-loading="loading" element-loading-text="Loading" :data="list" border stripe style="width: 100%" highlight-current-row>
					<el-table-column :label="$t('base.project.name')" :show-overflow-tooltip="true">
						<template slot-scope="s">
							<span>{{ s.row.projectName }}</span><!--项目名称-->
						</template>
					</el-table-column>
					<el-table-column :label="$t('base.device.position')" :show-overflow-tooltip="true">
						<template slot-scope="s">
							<span>{{ s.row.positionName }}</span><!--设备位置-->
						</template>
					</el-table-column>
					<el-table-column :label="$t('base.device.name')" :show-overflow-tooltip="true">
						<template slot-scope="s">
							<span>{{ s.row.deviceName }}</span><!--设备名称-->
						</template>
					</el-table-column>
					<el-table-column :label="$t('order.orderCode')" :show-overflow-tooltip="true">
						<template slot-scope="s">
							<span>{{ s.row.orderCode }}</span><!--工单编码-->
						</template>
					</el-table-column>
					<el-table-column :label="$t('order.faultDesc')" :show-overflow-tooltip="true">
						<template slot-scope="s">
							<span v-if="!s.row.alarmVo">{{ s.row.problemDesc }}</span><!--问题描述-->
							<span v-else>{{s.row.alarmDesc}}</span><!--问题描述-->
						</template>
					</el-table-column>
					<el-table-column :label="$t('order.orderConfigName')" :show-overflow-tooltip="true">
						<template slot-scope="s">
							<span>{{ s.row.configName }}</span><!--工单模版-->
						</template>
					</el-table-column>
					<el-table-column :label="$t('order.orderType')" :show-overflow-tooltip="true">
						<template slot-scope="s">
							<span>{{ s.row.orderTypeStr }}</span><!--工单类型-->
						</template>
					</el-table-column>
					<el-table-column :label="$t('order.serveTypeStr')" :show-overflow-tooltip="true">
						<template slot-scope="s">
							<span>{{ s.row.serviceType }}</span><!--服务类型-->
						</template>
					</el-table-column>
					<!--工单类型-->
					<!--<el-table-column :label="$t('order.orderType')" :show-overflow-tooltip="true">
						<template slot-scope="s">
							<template v-if="s.row.orderType == 1">
								<span>{{ $t("order.device") }}</span>
							</template>
							<template v-if="s.row.orderType == 2">
								<span>{{ $t("order.fireSafety") }}</span>
							</template>
							<template v-if="s.row.orderType == 3">
								<span>{{ $t("order.quality") }}</span>
							</template>
							<template v-if="s.row.orderType == 4">
								<span>{{ $t("order.sporadicMaintenance") }}</span>
							</template>
							<template v-if="s.row.orderType == 5">
								<span>{{ $t("order.cleaningHygiene") }}</span>
							</template>
							<template v-if="s.row.orderType == 6">
								<span>{{ $t("order.greenConservation") }}</span>
							</template>
							<template v-if="s.row.orderType == 7">
								<span>{{ $t("order.vulnerabilityInformation") }}</span>
							</template>
							<template v-if="s.row.orderType == 8">
								<span>{{ $t("order.customerDeclaration") }}</span>
							</template>
						</template>
					</el-table-column>-->
					<el-table-column :label="$t('order.serviceDept')" :show-overflow-tooltip="true">
						<template slot-scope="s">
							<span>{{ s.row.resDeptName }}</span><!--责任部门-->
						</template>
					</el-table-column>
					<el-table-column :label="$t('order.servicePeople')" :show-overflow-tooltip="true">
						<template slot-scope="s">
							<template v-if="s.row.orderStatus == 1">
								<span style="color: #ffc107">{{$t("order.waitReceive")}}</span><!--维修人员-->
							</template>
							<template v-else-if="s.row.orderStatus == 6">
								<span v-if="s.row.isSelf == 'Y'">{{s.row.submitUserName}}</span>
								<span v-else>/</span>
							</template>
							<template v-else>
								<span>{{ s.row.handlePersonName }}</span>
							</template>
						</template>
					</el-table-column>
					<el-table-column :label="$t('order.signTime')" :show-overflow-tooltip="true">
						<template slot-scope="s">
							<span v-if='s.row.orderSign'>{{s.row.orderSign.creationDate | dateFormat("YYYY-MM-DD HH:mm:ss")}}</span><!--签到时间-->
						</template>
					</el-table-column>
					<el-table-column :label="$t('order.declareDept')" :show-overflow-tooltip="true">
						<template slot-scope="s">
							<span>{{ s.row.submitUserDeptName }}</span><!--报单部门-->
						</template>
					</el-table-column>
					<el-table-column :label="$t('order.declarePeople')" :show-overflow-tooltip="true">
						<template slot-scope="s">
							<span>{{ s.row.submitUserName }}</span><!--报单人员-->
						</template>
					</el-table-column>
					<el-table-column :label="$t('order.status')" :show-overflow-tooltip="true">
						<template slot-scope="s"><!--工单状态-->
							<span v-if="s.row.orderStatus == 1">{{$t("order.waitReceive")}}</span>
							<span v-else-if="s.row.orderStatus == 2">{{$t("order.waitExecute")}}</span>
							<span v-else-if="s.row.orderStatus == 3">{{$t("order.executing")}}</span>
							<span v-else-if="s.row.orderStatus == 4">{{ $t("order.waitAcceptance")}}</span>
							<span v-else-if="s.row.orderStatus == 5">{{$t("order.completed")}}</span>
							<span v-else-if="s.row.orderStatus == 6">{{$t("order.applyReject")}}</span>
							<span v-else-if="s.row.orderStatus == 7">{{$t("order.acceptanceReject")}}</span>
							<span v-else-if="s.row.orderStatus == 8">{{$t("order.alreadyRevoke")}}</span>
							<span v-else-if="s.row.orderStatus == 9">{{$t("order.alreadyCloseOrder")}}</span>
						</template>
					</el-table-column>
					<el-table-column :label="$t('order.declareTime')" width="160">
						<template slot-scope="s">
							<span>{{s.row.creationDate | dateFormat("YYYY-MM-DD HH:mm:ss")}}</span><!--报单时间-->
						</template>
					</el-table-column>
					<el-table-column :label="$t('order.completeDetail')" :show-overflow-tooltip="true">
						<template slot-scope="s">
							<span v-if='s.row.orderStatus == 9'>{{ s.row.closeReason }}</span><!--关单原因-->
							<span v-else>
								<span v-if="s.row.orderStatus !== 7">
									{{ s.row.completeSituation }}</span>
								</span>
								<!--完成情况-->
						</template>
					</el-table-column>
					<el-table-column :label="$t('commons.details')" align="center" width="80" fixed="right">
						<template slot-scope="s">
							<el-tooltip :content="$t('commons.details')" placeman="bottom">
								<el-button @click="showOrderDetail(s.row)" circle type="success" icon="el-icon-tickets" size="mini">
								</el-button>
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>
				<pagination v-show="total > 0" :total="total" :page.sync="listQuery.current" :limit.sync="listQuery.rowCount" @pagination="getOrderPage" />
			</el-tab-pane>
			<el-tab-pane v-if="detailTab" :label="$t('order.details')" :name="detailTab.tabName" :key="detailTab.tabName" closable>
				<order-detail v-bind:orderRow="order" :key="order.orderId"></order-detail>
			</el-tab-pane>
		</el-tabs>

		<!--<order-fill :orderFillDialog="orderFillDialog" :isToolBar="false" />-->
	</div>
</template>

<script>
	import {
		getOrderPage,
		getCompanyList,
		getProjectList,
		getResDeptName,
		getEmployeeList,
		getPosition,
		exportOrder,
	} from "@/api/business/order/tenant/order";
	import store from "@/store/index";
	import { listToTree } from "@/utils/tree";
	import Pagination from "@/components/Pagination";
	import TreeSelect from "@riophae/vue-treeselect";
	import "@riophae/vue-treeselect/dist/vue-treeselect.css";
	import OrderDetail from "@/views/business/order/orderQuery/order/tenant/orderDetail";
	import OrderFill from "@/components/OrderFill";
	import handsRlink from "@/mixins/handsRlink.js";
	const defineTab = {
		listTab: {
			tabName: "1",
		},
		detailTab: {
			tabName: "2",
		},
	};

	export default {
		name: "orderManager",
		components: {
			Pagination,
			TreeSelect,
			OrderDetail,
			OrderFill
		},
		mixins: [handsRlink],
		data() {
			return {
				orderFillDialog: false,
				activeTabName: defineTab.listTab.tabName,
				detailTab: null,
				loading: true,
				isBorder: true,
				ableProject: true,
				ableDepartment: true,
				total: 0,
				list: null,
				data: [],
				companyList: [],
				projectList: [],
				departmentList: [],
				employeeList: [],
				editPositionList: [],
				listQuery: {
					current: 1,
					rowCount: 10,
					orderType: null,
					companyId: null,
					projectId: null,
					positionId: null,
					deviceName: null,
					reportStartDate: null,
					reportEndDate: null,
					resDeptId: null,
					handlePerson: null,
					reportDeptId: null,
					reportUserId: null,
					orderLevel: null,
					orderStatus: null,
					orderCode: null,
					orderConfigId:null,//工单模版
					serviceType:null,//服务类型
				},
				order: {
					orderId: null,
				},
				tempArrs:[],//工单模版数据
				servTypeArrs:[],//服务类型
			};
		},
		created() {
			this.initServiceArrs();
			this.searchWithCon();
			this.getCompanyList();
			this.getPosition();
			this.getEmployeeList();
			
			const {query}=this.$route;
			if(query.orderId){
				this.showOrderDetail(query)
			}
		},
		computed: {
			listTab() {
				return defineTab.listTab;
			},
		},
		methods: {
			projectChange(){
				this.tempArrs=[];
				this.listQuery.orderConfigId='';
				this.initComplist(this.listQuery.projectId);
			},
			async initServiceArrs() {
				//获取服务类型
				let res = await this.ApiHttp('/lookup/lookup/item/find',{
					isloading:false,
					classifyCode:'ORDER_SERVICE_TYPE',
				});
				if(res){
					this.servTypeArrs=res.lookupItemList;//服务类型数据
				}
			},
			async initComplist(proId){
				if(!proId) return;
				let res=await this.ApiHttp('/order/tenant/orderConfig/getConfigList?projectId='+proId);
				this.tempArrs=res;
			},
			// 关闭项Tab
			removeTab(tabName) {
				this.activeTabName = defineTab.listTab.tabName;
				if(tabName == "2") {
					this.detailTab = null;
				}
			},
			// 展示工单详情
			showOrderDetail(row) {
				// 打开tab
				this.detailTab = defineTab.detailTab;
				this.order = {...row};
				this.activeTabName = defineTab.detailTab.tabName;
			},
			pickerStart(start) {
				this.listQuery.reportStartDate = start.getTime();
			},
			pickerEnd(end) {
				this.listQuery.reportEndDate = end.getTime();
			},
			//设备工单列表
			getOrderPage() {
				this.loading = true;
//				if(!this.listQuery.deviceName) {
//					this.listQuery.deviceName = null;
//				}
				for(let keys in this.listQuery){
					if(this.listQuery[keys] === '') this.listQuery[keys]=null;
				}
				getOrderPage(this.listQuery)
					.then((res) => {
						res.rows.map(r=>{
							let alarmVo=r.alarmVo;
							if(alarmVo){
								r.alarmDesc='告警类型:'+ this.AlarmTypeObj[alarmVo.alarmType]+'; 告警内容:'+alarmVo.alarmContent+'; 告警值:'+(alarmVo.alarmValue||'-')+'; 告警时间:'+this.common.getTimeMmss(alarmVo.alarmTimestamp)+'; 告警参数:'+(alarmVo.propertyDescription||'-')
							}
						})
						this.list = res.rows;
						this.total = res.total;
					})
					.finally(() => {
						this.loading = false;
					});
			},
			//加载公司列表
			getCompanyList() {
				getCompanyList().then((res) => {
					this.companyList = res;
				});
			},
			//清除
			clearForm() {
				this.listQuery.orderType = null;
				this.listQuery.companyId = null;
				this.listQuery.projectId = null;
				this.listQuery.positionId = null;
				this.listQuery.deviceName = null;
				this.listQuery.reportStartDate = null;
				this.listQuery.reportEndDate = null;
				this.listQuery.resDeptId = null;
				this.listQuery.handlePerson =null;
				this.listQuery.reportDeptId = null;
				this.listQuery.reportUserId = null;
				this.listQuery.orderLevel = null;
				this.listQuery.orderStatus = null;
				this.listQuery.orderConfigId = null;
				this.listQuery.serviceType = null;
				this.listQuery.orderCode = null;
				this.ableProject = true;
				this.ableDepartment = true;
				
			},
			//查询
			searchWithCon() {
				this.listQuery.current = 1;
				this.getOrderPage();
			},
			//切换公司
			changeCompany(companyId) {
				if(companyId) {
					this.ableDepartment = false;
					this.ableProject = false;
					this.listQuery.projectId = null;
					this.listQuery.resDeptId = null;
					this.listQuery.reportDeptId = null;
					getProjectList({
						companyId: companyId
					}).then((res) => {
						this.projectList = res;
					});
					getResDeptName({
						companyId: companyId
					}).then((resu) => {
						this.departmentList = resu;
					});
				} else {
					this.departmentList = [];
					this.projectList = [];
					this.listQuery.projectId = null;
					this.listQuery.resDeptId = null;
					this.listQuery.reportDeptId = null;
					this.ableDepartment = true;
					this.ableProject = true;
				}
			},
			//加载维修人员列表
			getEmployeeList() {
				getEmployeeList().then((res) => {
					this.employeeList = res;
				});
			},
			getPosition() {
				getPosition().then((res) => {
					let data = listToTree(res, "positionId", "parentId");
					this.recursivePosition(data);
				});
			},
			recursivePosition(row) {
				let data = row.map((v) => {
					v.label = v.positionName;
					v.id = v.positionId;
					if(v.children != undefined) {
						this.recursivePosition(v.children);
					}
					return v;
				});
				this.editPositionList = data;
			},
			exportOrder() {
				exportOrder(this.listQuery).then((msg) => {
					this.$message({
						type: "success",
						message: this.$t("message.operationSuccess"),
					});
					let exportObj = {
						taskId: msg,
						taskName: "Order",
						taskStatus: 0,
						rootPath: "basePath",
					};
					//将导出任务丢入导出任务列表中
					store.dispatch("PushExportNotice", exportObj);
				});
			},
			clearEmpty(field) {
				this.listQuery[field] = null;
			},
			drawerClick() {
				this.orderFillDialog = true;
			},
		},
	};
</script>