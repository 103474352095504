<template>
  <div>
    <el-drawer
      style="line-height: 16px"
      :size="638"
      class="order-drawer"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleCloseDrawer"
    >
      <template slot="title">
        <span><title-icon />{{ $t("order.fill") }}</span>
      </template>
      <div class="data-list">
        <el-row class="data-row">
          <el-col :span="4" :offset="1">
            {{ $t("base.project.name") }}
          </el-col>
          <el-col :span="18">
            <span class="notify-sender">
              <el-select
                :style="{ width: '100%' }"
                @change="changeFormProject"
                clearable
                v-model="form.projectId"
              >
                <el-option
                  v-for="item in projectList"
                  :label="item.projectName"
                  :value="item.projectId"
                  :key="item.projectId"
                >
                </el-option>
              </el-select>
            </span>
          </el-col>
        </el-row>
        <el-row class="data-row">
          <el-col :span="4" :offset="1">
            {{ $t("base.position.name") }}
          </el-col>
          <el-col :span="18">
            <span class="notify-sender">
              <tree-select
                v-model="form.positionId"
                :disable-branch-nodes="true"
                :multiple="false"
                :show-count="true"
                :placeholder="$t('commons.selectPlease')"
                :options="formPositionList"
              />
            </span>
          </el-col>
        </el-row>
        <el-row class="data-row">
          <el-col :span="4" :offset="1">
            {{ $t("base.device.select") }}
          </el-col>
          <el-col :span="18">
            <span class="notify-sender">
              <el-autocomplete
                v-model="inputValue"
                :fetch-suggestions="querySearch"
                clearable
                :placeholder="$t('commons.pleaseInput')"
                @clear="clearDevice"
                @select="handleSelect"
                @input="initDeviceList"
              >
              </el-autocomplete>
              <el-button
                type="primary"
                icon="el-icon-search"
                style="margin-left: 6px"
                @click="openDeviceSearch"
                >{{ $t("base.device.advanced") }}</el-button
              >
            </span>
          </el-col>
        </el-row>
        <!--<el-row class="data-row" v-if="positionName != null">-->
        <!--<el-col :span="4" :offset="1">-->
        <!--{{$t('commons.location')}}-->
        <!--</el-col>-->
        <!--<el-col :span="18">-->
        <!--<span class="notify-sender">-->
        <!--{{positionName}}-->
        <!--</span>-->
        <!--</el-col>-->
        <!--</el-row>-->
        <el-row class="data-row">
          <el-col :span="4" :offset="1">
            {{ $t("order.faultDesc") }}
          </el-col>
          <el-col :span="18">
            <span class="notify-sender">
              <el-input
                type="textarea"
                maxlength="80"
                :show-word-limit="true"
                :rows="2"
                :placeholder="$t('commons.pleaseInput')"
                v-model="form.problemDesc"
              ></el-input>
            </span>
          </el-col>
        </el-row>
        <el-row class="data-row">
          <el-col :span="4" :offset="1">
            {{ $t("order.orderType") }}
          </el-col>
          <el-col :span="18">
            <span class="notify-sender">
              <el-select v-model="form.orderType" :style="{ width: '100%' }">
                <el-option value="1" :label="$t('order.device')"></el-option>
                <el-option
                  value="2"
                  :label="$t('order.fireSafety')"
                ></el-option>
                <el-option value="3" :label="$t('order.quality')"></el-option>
                <el-option
                  value="4"
                  :label="$t('order.sporadicMaintenance')"
                ></el-option>
                <el-option
                  value="5"
                  :label="$t('order.cleaningHygiene')"
                ></el-option>
                <el-option
                  value="6"
                  :label="$t('order.greenConservation')"
                ></el-option>
                <el-option
                  value="7"
                  :label="$t('order.vulnerabilityInformation')"
                ></el-option>
                <el-option
                  value="8"
                  :label="$t('order.customerDeclaration')"
                ></el-option>
              </el-select>
            </span>
          </el-col>
        </el-row>
        <el-row class="data-row">
          <el-col :span="4" :offset="1">
            {{ $t("order.level") }}
          </el-col>
          <el-col :span="18">
            <span class="notify-sender">
              <el-select v-model="form.orderLevel" :style="{ width: '100%' }">
                <el-option
                  value="0"
                  :label="$t('order.config.ordinary')"
                ></el-option>
                <el-option
                  value="1"
                  :label="$t('order.config.urgent')"
                ></el-option>
                <el-option
                  value="2"
                  :label="$t('order.config.alarm')"
                ></el-option>
              </el-select>
            </span>
          </el-col>
        </el-row>
        <template v-if="!(form.bySelfChecked || form.repairPerson != null)">
          <el-row class="data-row">
            <el-col :span="4" :offset="1">
              {{ $t("order.serviceDept") }}
            </el-col>
            <el-col :span="18">
              <span class="notify-sender">
                <el-select
                  v-model="form.resDeptId"
                  clearable
                  :style="{ width: '100%' }"
                >
                  <el-option
                    v-for="item in departmentList"
                    :label="item.deptName"
                    :value="item.deptId"
                    :key="item.deptId"
                  >
                  </el-option>
                </el-select>
              </span>
            </el-col>
          </el-row>
        </template>
        <el-row class="data-row">
          <el-col :span="4" :offset="1">
            {{ $t("order.livePhoto") }}
          </el-col>
          <el-col :span="18">
            <span class="notify-sender">
              <div class="image_container">
                <span
                  class="image_single"
                  v-for="(image, index) in imageList"
                  :key="index"
                >
                  <img :src="image.url" />
                  <i
                    class="el-icon-circle-plus-outline img_oper_icon"
                    @click="singleImagePreview(image)"
                  ></i>
                  <i
                    class="el-icon-delete img_oper_icon"
                    @click.stop="singleImageDelete(image)"
                  ></i>
                </span>
              </div>
              <el-upload
                v-loading="isUploading"
                list-type="picture-card"
                :show-file-list="false"
                :on-success="uploadSuccess"
                :on-error="uploadFail"
                action="#"
                ref="uploadFaultImage"
                :with-credentials="true"
                :on-preview="handlePictureCardPreview"
                :on-progress="handleUploading"
                :on-remove="handleRemove"
                :on-exceed="handleExceed"
                :before-upload="beforeAvatarUpload"
                :http-request="httpRequest"
                accept=".jpg,.png"
                drag
                multiple
                :limit="9"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </span>
          </el-col>
        </el-row>
        <el-row class="data-row">
          <el-col :span="4" :offset="1">
            <el-checkbox v-model="form.bySelfChecked">{{
              $t("order.makeMe")
            }}</el-checkbox>
          </el-col>
        </el-row>
        <el-row class="data-row">
          <el-button
            type="primary"
            style="width: 90%; margin-left: 5%"
            :disabled="!canSubmit()"
            @click="submitOrder"
            >{{ $t("commons.submit") }}</el-button
          >
        </el-row>
      </div>
    </el-drawer>

    <el-dialog
      :visible.sync="imageDialogVisible"
      width="50%"
      top="6vh"
      style="height: 96vh"
    >
      <img style="height: 80vh; width: 100%" :src="dialogImageUrl" alt="" />
    </el-dialog>

    <el-dialog
      :visible.sync="dialogVisible"
      width="80%"
      top="6vh"
      style="line-height: normal"
      :before-close="handleClose"
    >
      <template slot="title">
        <title-icon />{{ $t("base.device.select") }}
      </template>
      <span>
        <el-form role="form" label-width="100px">
          <el-row>
            <el-col :span="6">
              <el-form-item :label="$t('base.project.name')">
                <el-select
                  :style="{ width: '100%' }"
                  ref="project"
                  @change="changeProject"
                  clearable
                  v-model="device.listQuery.projectId"
                >
                  <el-option
                    v-for="item in projectList"
                    :label="item.projectName"
                    :value="item.projectId"
                    :key="item.projectId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item :label="$t('base.device.position')">
                <tree-select
                  v-model="device.listQuery.positionId"
                  :disable-branch-nodes="true"
                  :multiple="false"
                  :show-count="true"
                  :placeholder="$t('commons.selectPlease')"
                  :options="positionList"
                  @select="searchCondition"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item :label="$t('base.category.system')">
                <tree-select
                  v-model="device.listQuery.categoryId"
                  :multiple="false"
                  :show-count="true"
                  :placeholder="$t('commons.selectPlease')"
                  :options="categoryList"
                  @select="searchCondition"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item :label="$t('base.device.name')">
                <el-input
                  v-model="device.listQuery.deviceName"
                  maxlength="25"
                  :show-word-limit="true"
                  @keyup.enter.native="searchCondition"
                  :placeholder="$t('commons.pleaseInput')"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item :label="$t('base.device.factoryCode')">
                <el-input
                  v-model="device.listQuery.factoryCode"
                  maxlength="50"
                  :show-word-limit="true"
                  @keyup.enter.native="searchCondition"
                  :placeholder="$t('commons.pleaseInput')"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="18">
              <el-button
                @click="searchCondition"
                type="primary"
                icon="el-icon-search"
                :loading="device.listLoading"
                :style="{ marginLeft: '30px' }"
                >{{ $t("commons.search") }}</el-button
              >
            </el-col>
          </el-row>
        </el-form>

        <el-table
          v-loading="device.listLoading"
          :data="device.list"
          ref="singleTable"
          highlight-current-row
          element-loading-text="loading"
          border
          fit
          stripe
        >
          <el-table-column prop="projectName" :label="$t('base.project.name')">
            <template slot-scope="scope">
              <span>{{ scope.row.projectName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="deviceName" :label="$t('base.device.name')">
            <template slot-scope="scope">
              <span>{{ scope.row.deviceName }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('base.device.position')">
            <template slot-scope="scope">
              <el-tooltip :content="scope.row.descName" placement="top">
                <span>{{ scope.row.positionName }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column :label="$t('base.category.system')">
            <template slot-scope="scope">
              <span>{{ scope.row.categoryName }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('commons.actions')" align="center">
            <template slot-scope="scope">
              <el-button @click="selectDevice(scope.row)" type="text">{{
                $t("commons.select")
              }}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="device.total > 0"
          :total="device.total"
          :page.sync="device.listQuery.current"
          :limit.sync="device.listQuery.rowCount"
          @pagination="getDevicePage"
        />
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getImpDept,
  deviceEnterOrder,
} from "@/api/business/order/tenant/orderFill";
import {
  getDevicePage,
  getPositionTree,
} from "@/api/business/base/tenant/device";
import { uploadImages } from "@/api/business/order/tenant/order";
import { getCategoryList } from "@/api/business/base/tenant/category";
import { getProjectList } from "@/api/business/base/tenant/project";
import { envInfo } from "@/constants/envInfo";
import { listToTree } from "@/utils/tree";
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Pagination from "../Pagination";
import { watermark } from "@/utils/global";

export default {
  name: "OrderFill",
  components: {
    Pagination,
    TreeSelect,
  },
  props: {
    //是否打开
    orderFillDialog: {
      type: Boolean,
    },
    //是否右上角工具按钮
    isToolBar: {
      type: Boolean,
    },
  },
  computed: {
    isOrderShow() {
      return this.orderFillDialog;
    },
  },
  watch: {
    isOrderShow: function () {
      if (this.orderFillDialog) {
        this.drawerClick();
      }
    },
  },
  data() {
    return {
      watermark,
      imageList: [],
      radio: "",
      uploadPath:
        envInfo.bgApp.archivePath +
        "/param/archive/upload?ulType=DefaultUpload",
      drawer: false,
      direction: "rtl",
      inputValue: null,
      deviceList: [],
      //设备选择查询
      listQuery: {
        deviceName: null,
        current: 1,
        rowCount: 20,
      },
      //所在全路径位置
      positionName: null,
      form: {
        deviceId: null,
        deviceName: null,
        problemDesc: null,
        projectId: null,
        positionId: null,
        subResource: [],
        orderType: null,
        orderLevel: 0 + "",
        isSelf: null,
        repairPerson: null,
        source: 0,
        positionName: null,
        resDeptId: null,
        bySelfChecked: false,
      },
      departmentList: [],
      dialogVisible: false,
      device: {
        title: null,
        total: 0,
        list: null,
        listLoading: false,
        listQuery: {
          current: 1,
          rowCount: 10,
          projectId: null,
          deviceName: null,
          positionId: null,
          categoryId: null,
          factoryCode: null,
        },
      },
      projectList: [],
      categoryList: [],
      positionList: [],
      formPositionList: [],
      dialogImageUrl: "",
      imageDialogVisible: false,
      // 图片是否在上传中
      isUploading: false,
    };
  },
  created() {
    this.getProjectList();
  },
  methods: {
    singleImageDelete({ id }) {
      this.form.subResource = this.form.subResource.filter(
        (item) => item.fileId !== id
      );
      this.imageList = this.imageList.filter((item) => item.id !== id);
    },
    singleImagePreview({ url }) {
      this.dialogImageUrl = url;
      this.imageDialogVisible = true;
    },
    //点击填报工单，打开侧边栏
    drawerClick() {
      if (!this.drawer) {
        this.drawer = true;
      }
    },
    initDeviceList() {
      this.positionName = null;
      this.listQuery.deviceName = this.inputValue;
      return getDevicePage(this.listQuery)
        .then((res) => {
          let deviceList = [];
          res.rows.map((v) => {
            deviceList.push({ value: v.deviceName, info: v });
          });
          this.deviceList = deviceList;
        })
        .catch((error) => {
          console.log(`查询失败，原因=> ${error}`);
        });
    },
    querySearch(queryString, cb) {
      this.initDeviceList().then(() => {
        let deviceList = this.deviceList;
        let results = queryString
          ? deviceList.filter(this.createFilter(queryString))
          : deviceList;
        // 调用 callback 返回建议列表的数据
        cb(results);
      });
    },
    createFilter(queryString) {
      return (device) => {
        return (
          device.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      this.inputValue = item.value.trim();
      this.positionName = item.info.descName;
      this.form.deviceId = item.info.deviceId;
      this.form.deviceName = item.info.deviceName;
      this.form.projectId = item.info.projectId;
      this.form.positionName = item.info.positionName;
      this.form.repairPerson = item.info.repairPerson;

      getPositionTree({ projectId: item.info.projectId }).then((response) => {
        this.formPositionList = [];
        let data = listToTree(response, "positionId", "parentId");
        this.recursiveFormPosition(data);
        this.form.positionId = item.info.positionId;
      });

      // 如果项目不一样，清空并刷新维修部门，如果项目一样，就不用清空刷新
      if (this.form.projectId != item.info.projectId) {
        this.form.projectId = item.info.projectId;
        this.form.resDeptId = null;
        getImpDept({ projectId: item.info.projectId }).then((res) => {
          this.departmentList = res;
        });
      }
    },
    //故障图片上传
    handleRemove(file) {
      console.log(file);
    },
    httpRequest(option) {
      this.isUploading = true;
      const { file } = option;
      const fileObj = {};
      const reader = new FileReader();
      reader.readAsDataURL(file); // 得到base64编码格式
      reader.onload = async (e) => {
        const url = URL.createObjectURL(
          this.dataURLtoBlob(e.currentTarget.result)
        );
        fileObj.url = url;
        this.watermark(url, [this.watermarkTimes(), "Rwork"]).then((cres) => {
          this.uploadImageHandler(file, cres);
        });
      };
    },
    watermarkTimes() {
      let D = new Date();
      return (
        D.getFullYear() +
        "." +
        (D.getMonth() + 1).toString().padStart(2, "0") +
        "." +
        D.getDate().toString().padStart(2, "0") +
        "\xa0" +
        D.getHours() +
        ":" +
        D.getMinutes()
      );
    },
    uploadImageHandler(file, cres) {
      const newFile = new File([this.dataURLtoBlob(cres.src)], file.name, {
        type: file.type,
      });
      const formdata = new FormData();
      formdata.append("upload", newFile);
      // 转为blob对象
      uploadImages(formdata)
        .then((res) => {
          this.form.subResource.push({
            fileId: res[0].fileId,
            fileType: res[0].contentType,
          });
          this.imageList.push({
            id: res[0].fileId,
            url:
              envInfo.bgApp.archivePath +
              "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
              res[0].fileId,
          });
        })
        .finally(() => {
          this.isUploading = false;
        });
    },
    // 将base64转换为blob对象
    // dataURLtoBlob(dataurl) {
    //   const arr = dataurl.split(",");
    //   const mime = arr[0].match(/:(.*?);/)[1];
    //   const bstr = window.atob(arr[1]);
    //   let n = bstr.length;
    //   const u8arr = new Uint8Array(n);
    //   // eslint-disable-next-line no-plusplus
    //   while (n--) {
    //     u8arr[n] = bstr.charCodeAt(n);
    //   }
    //   return new Blob([u8arr], { type: mime });
    // },
    dataURLtoBlob(base64Data) {
      var byteString;
      if (base64Data.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(base64Data.split(",")[1]);
      // base64 解码
      else {
        byteString = unescape(base64Data.split(",")[1]);
      }
      var mimeString = base64Data.split(",")[0].split(":")[1].split(";")[0]; // mime类型 -- image/png

      // var arrayBuffer = new ArrayBuffer(byteString.length); //创建缓冲数组
      // var ia = new Uint8Array(arrayBuffer);//创建视图
      var ia = new Uint8Array(byteString.length); // 创建视图
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      var blob = new Blob([ia], {
        type: mimeString,
      });
      return blob;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    uploadSuccess(response, file, fileList) {
      let subResource = [];
      fileList.map((v) => {
        subResource.push(v.response[0].fileId);
      });
      this.form.subResource = subResource;
      this.isUploading = false;
    },
    //图片上传失败回调
    uploadFail() {
      this.$message({ type: "error", message: this.$t("message.uploadFial") });
    },
    handleExceed() {
      this.$message.warning(this.$t("order.uploadLimit"));
    },

    //设备高级查找
    handleClose() {
      this.dialogVisible = false;
    },
    openDeviceSearch() {
      this.dialogVisible = true;
      if (this.form.projectId) {
        this.device.listQuery.projectId = this.form.projectId;
      } else {
        this.device.listQuery.projectId = null;
      }
      if (this.form.positionId) {
        getPositionTree({ projectId: this.form.projectId }).then((response) => {
          this.positionList = [];
          let data = listToTree(response, "positionId", "parentId");
          this.recursivePosition(data);
          this.device.listQuery.positionId = this.form.positionId;
        });
      } else {
        this.device.listQuery.positionId = null;
      }
      this.getDevicePage();
      this.getCategoryList();
    },
    searchCondition() {
      this.device.listQuery.current = 1;
      this.getDevicePage();
    },
    getDevicePage() {
      this.device.listLoading = true;
      getDevicePage(this.device.listQuery).then((response) => {
        this.device.total = response.total;
        this.device.list = response.rows;
        this.device.listLoading = false;
      });
    },
    getCategoryList() {
      getCategoryList().then((response) => {
        let data = listToTree(response, "categoryId", "parentId");
        this.recursiveCategory(data);
      });
    },
    //递归系统列表修改属性及数据结构
    recursiveCategory(row) {
      let data = row.map((v) => {
        v.label = v.categoryName;
        v.id = v.categoryId;
        if (v.children != undefined) {
          this.recursiveCategory(v.children);
        }
        return v;
      });
      this.categoryList = data;
    },
    getProjectList(companyId) {
      getProjectList({ companyId: companyId }).then((res) => {
        this.projectList = res;
        if (this.projectList.length == 1) {
          this.form.projectId = this.projectList[0].projectId;
          this.changeFormProject();
        }
      });
    },
    changeProject() {
      if (this.device.listQuery.projectId == "") {
        this.device.listQuery.projectId = null;
        this.device.listQuery.positionId = null;
        this.positionList = [];
      } else {
        getPositionTree({ projectId: this.device.listQuery.projectId }).then(
          (response) => {
            this.device.listQuery.positionId = null;
            this.positionList = [];
            let data = listToTree(response, "positionId", "parentId");
            this.recursivePosition(data);
          }
        );
      }
    },
    changeFormProject() {
      if (this.form.projectId == "") {
        this.form.projectId = null;
        this.form.positionId = null;
        this.formPositionList = [];
      } else {
        getPositionTree({ projectId: this.form.projectId }).then((response) => {
          this.form.positionId = null;
          this.formPositionList = [];
          let data = listToTree(response, "positionId", "parentId");
          this.recursiveFormPosition(data);
        });

        getImpDept({ projectId: this.form.projectId }).then((res) => {
          this.departmentList = res;
        });
      }
    },
    //递归位置列表修改属性及数据结构
    recursivePosition(row) {
      let data = row.map((v) => {
        v.label = v.positionName;
        v.id = v.positionId;
        if (v.children != undefined) {
          this.recursivePosition(v.children);
        }
        return v;
      });
      this.positionList = data;
    },
    //递归位置列表修改属性及数据结构
    recursiveFormPosition(row) {
      let data = row.map((v) => {
        v.label = v.positionName;
        v.id = v.positionId;
        if (v.children != undefined) {
          this.recursiveFormPosition(v.children);
        }
        return v;
      });
      this.formPositionList = data;
    },
    selectDevice(row) {
      this.dialogVisible = false;
      this.device.listQuery = {
        current: 1,
        rowCount: 10,
        projectId: null,
        deviceName: null,
        positionId: null,
        categoryId: null,
        factoryCode: null,
      };
      this.inputValue = row.deviceName.trim();
      this.positionName = row.descName;
      this.form.deviceId = row.deviceId;
      this.form.deviceName = row.deviceName;
      this.form.positionName = row.positionName;
      this.form.repairPerson = row.repairPerson;

      getPositionTree({ projectId: row.projectId }).then((response) => {
        this.formPositionList = [];
        let data = listToTree(response, "positionId", "parentId");
        this.recursiveFormPosition(data);
        this.form.positionId = row.positionId;
      });

      // 如果项目不一样，清空并刷新维修部门，如果项目一样，就不用清空刷新
      if (this.form.projectId != row.projectId) {
        this.form.projectId = row.projectId;
        this.form.resDeptId = null;
        getImpDept({ projectId: row.projectId }).then((res) => {
          this.departmentList = res;
        });
      }
    },
    clearDevice() {
      this.inputValue = null;
      this.form.deviceId = null;
      this.form.deviceName = null;
      this.form.repairPerson = null;
    },
    submitOrder() {
      this.form.isSelf = this.form.bySelfChecked == true ? "Y" : "N";
      this.form.subResource =
        this.form.subResource == null ? [] : this.form.subResource;
      deviceEnterOrder(this.form).then(() => {
        this.$message.success(this.$t("order.submitSuccess"));
        this.drawer = false;
        // 清空已上传图片列表
        this.$refs.uploadFaultImage.clearFiles();
        this.form = {
          deviceId: null,
          deviceName: null,
          problemDesc: null,
          projectId: null,
          positionId: null,
          subResource: [],
          orderType: null,
          orderLevel: 0 + "",
          isSelf: null,
          repairPerson: null,
          source: 0,
          positionName: null,
          resDeptId: null,
          bySelfChecked: false,
        };
        this.imageList = [];
        this.positionName = null;
        this.inputValue = null;
        if (this.isToolBar) {
          this.$parent.orderFillDialog = false;
        } else {
          this.$parent.orderFillDialog = false;
        }
      });
    },
    handleUploading() {
      this.isUploading = true;
    },
    handleCloseDrawer(done) {
      this.$confirm(this.$t("order.cancelPrompt"))
        .then(() => {
          done();
          this.form = {
            deviceId: null,
            deviceName: null,
            problemDesc: null,
            projectId: null,
            positionId: null,
            subResource: [],
            orderType: null,
            orderLevel: 0 + "",
            isSelf: null,
            repairPerson: null,
            source: 0,
            positionName: null,
            resDeptId: null,
            bySelfChecked: false,
          };
          this.positionName = null;
          this.inputValue = null;
          this.imageList = [];
          if (this.isToolBar) {
            this.$parent.orderFillDialog = false;
          } else {
            this.$parent.orderFillDialog = false;
          }
        })
        .catch(() => {});
    },
    /**
     * 选择了设备和维修部门才可以提交
     */
    canSubmit() {
      // 项目、问题原因、工单类型必选 图片上传中不允提交
      if (
        !this.form.projectId ||
        !this.form.problemDesc ||
        !this.form.orderType ||
        this.isUploading
      ) {
        return false;
      }
      // else if (!(this.form.bySelfChecked || this.form.repairPerson) && !this.form.resDeptId) {
      //     // 不是自己做单，或设备没有指定维修人时,部门必选
      //     return false;
      // }
      return true;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.imageDialogVisible = true;
    },
  },
};
</script>

<style scoped>
.order-fill-icon >>> i {
  width: 22px;
  height: 22px;
  cursor: pointer;
  fill: #48576a;
  vertical-align: 15px;
}

.notify-sender {
  font-size: 14px;
}
.image_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.img_oper_icon {
  position: absolute;
  top: calc(50% - 8px);
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  display: none;
}
.el-icon-delete {
  left: 80px;
}
.el-icon-circle-plus-outline {
  left: 40px;
}
.image_single:hover {
  background: #00000050;
}
.image_single:hover .img_oper_icon {
  display: inline-block;
}
.image_single {
  width: 146px;
  height: 146px;
  margin-bottom: 10px;
  margin-right: 10px;
  position: relative;
}
.image_single img {
  height: 100%;
  width: 100%;
}
.image_single img:hover {
  opacity: 0.7;
}
.data-list {
  border-top: 1px solid #f1f3f8;
  height: 800px;
  overflow-y: scroll;
  padding-bottom: 60px;
}

.data-row {
  padding: 4px 0px;
  line-height: 32px;
  border-bottom: 1px solid #f1f3f8;
}

.order-drawer >>> .el-upload-dragger {
  width: 100%;
  height: 100%;
}
</style>
